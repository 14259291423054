<template>
	<div class="container">
		<div class="title">
      <span class="inner_title">新增岗位</span>
    </div>
		<div>
      <div class="item-box">
				<span class="item-left">部门名：</span>
				<el-input class="rantHouseName" v-model="title"  placeholder="请输入"></el-input>
			</div>
			<div class="sub-box">
        <!-- 新增 -->
				<el-button v-if="!id" class="submitBtn" type="primary" plain @click="submitAdd">提交</el-button>
        <!-- 编辑 -->
        <el-button v-else class="submitBtn" type="primary" plain @click="submitEdit">提交</el-button>
			</div>
		</div>
	</div>
</template>
<script>
  import {addDepartmentFn,getDepartmentDetailsFn,editDepartmentFn } from '@/api/department.js'
	export default {
		props: [],
		data() {
			return {
        id:'',
        title:'',
      }
		},
    created() {
      this.id = this.$route.query.id
      if (this.id){
        this.getDepartmentDetails()
      }
    },
		mounted() {
		},
		methods: {
      //获取部门详情
      getDepartmentDetails(){
        let params = {
          api_token:localStorage.getItem('token1'),
          id:this.id
        }
        getDepartmentDetailsFn(params).then(res => {
          this.title = res.datas.title
        })
      },
      submitEdit(){
        let params ={
          api_token:localStorage.getItem('token1'),
					title:this.title,
          id:this.id
        }
				editDepartmentFn(params).then(res => {
					if (res.code == 200) {
						this.$confirm("提交成功", "提交成功", {
							confirmButtonText: "确定",
							callback: () => {
								this.$router.push({
									path: "/departmentList",
									query: {
										page: this.page,
										last_page: this.last_page
									}
								});
							}
						});
					}
				});
      },
			//提交
			submitAdd() {
        let params ={
          api_token:localStorage.getItem('token1'),
					title:this.title,
        }
				addDepartmentFn(params).then(res => {
					if (res.code == 200) {
						this.$confirm("提交成功", "提交成功", {
							confirmButtonText: "确定",
							callback: () => {
								this.$router.push({
									path: "/departmentList",
									query: {
										page: this.page,
										last_page: this.last_page
									}
								});
							}
						});
					}
				});
			},
		},
	};
</script>
<style scoped>
	@import url("../../../style/detail.css");
</style>
